import { useEffect, useState } from "react";
import MaterialTable, { Column } from "material-table";
import { AccessTime, Cancel, DirectionsBus, Edit } from "@material-ui/icons";
import moment from "moment";

// hooks
import { useStores } from "stores";
// components
import {
  ChangeBusModal,
  ConfirmRemoBusModal,
  EditRouteModal,
  ScheduleModal,
} from "./components";
// assets
import alert from "assets/alerta.png";

export function BusRoutes() {
  //hooks
  const { busRoutes, auth } = useStores();

  // state
  const [modalOpen, setModalOpen] = useState(false);
  const [busModalOpen, setBusModalOpen] = useState(false);
  const [removeBusModalOpen, setRemoveBusModalOpen] = useState(false);
  const [editRouteModalOpen, setEditRouteModalOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState({} as BusRoute);

  // constants
  const accessType = auth.userData?.access_type;
  const isAdm = accessType === "admin";
  const isOperatorManager = accessType === "operator_manager";

  useEffect(() => {
    busRoutes.loadRoutes();
    busRoutes.loadBuses();
  }, []);

  const handleSchedule = (route: BusRoute) => {
    setSelectedRoute(route);
    setModalOpen(true);
  };

  const handleSelectBus = (route: BusRoute) => {
    setSelectedRoute(route);
    setBusModalOpen(true);
  };

  const handleOpenRemoveBus = (route: BusRoute) => {
    setSelectedRoute(route);
    setRemoveBusModalOpen(true);
  };

  const handleEditRoute = (route: BusRoute) => {
    setSelectedRoute(route);
    setEditRouteModalOpen(true);
  };

  const checkLastUpateIsToday = (route: BusRoute) => {
    const lastUpdate = route.last_update_info?.timestamp.toDate();
    const today = moment().toDate();
    const isSaturday = today.getDay() === 6;
    const isSunday = today.getDay() === 0;

    // check if there is no schedule for the day
    if (isSaturday && !route?.saturday_bus_schedule?.length) return true;
    if (isSunday && !route?.sunday_bus_schedule?.length) return true;
    if (!lastUpdate) return false;

    return (
      lastUpdate.getDate() === today.getDate() &&
      lastUpdate.getMonth() === today.getMonth() &&
      lastUpdate.getFullYear() === today.getFullYear()
    );
  };

  const columns: Column<BusRoute>[] = [
    {
      title: "Rota",
      field: "name",
      editable: "always",
      render: (data) => (
        <>
          <b>
            {data.route_number} - {data.name}
          </b>
          <br />
          {data.description}
        </>
      ),
    },
    {
      title: "Onibus",
      field: "bus_stops_count",
      align: "center",
      editable: "never",
      render: (rowData) => {
        const buses = rowData.buses || [];

        if (!buses.length)
          return (
            <div>
              <img src={alert} alt="alerta" height={25} /> <br />
              <b>Sem ônibus</b>
              {rowData?.last_update_info && (
                <>
                  <br />
                  Atulizado:{" "}
                  {moment(rowData?.last_update_info?.timestamp.toDate()).format(
                    "DD/MM - HH:mm",
                  )}
                  <br />
                  Operador: {rowData?.last_update_info?.operator_name}
                </>
              )}
            </div>
          );

        return (
          <div>
            {<b>{buses.map((bus) => bus.bus_number).join(" - ")}</b>}
            {rowData?.last_update_info && (
              <>
                <br />
                Atulizado em:{" "}
                {moment(rowData?.last_update_info?.timestamp.toDate()).format(
                  "DD/MM - HH:mm",
                )}
                <br />
                Operador: {rowData?.last_update_info?.operator_name}
              </>
            )}
            {!checkLastUpateIsToday(rowData) && (
              <div style={{ marginTop: 3 }}>
                <b>Não atualizado hoje</b>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        title={"Rotas - " + busRoutes.routesData.length}
        isLoading={busRoutes.isLoading}
        columns={columns}
        data={busRoutes.routesData}
        options={{
          pageSize: 150,
          pageSizeOptions: [20, 50, 150],
          emptyRowsWhenPaging: false,
          search: false,
          rowStyle: (rowData) => ({
            backgroundColor: !checkLastUpateIsToday(rowData)
              ? "#fcccba"
              : "#fff",
          }),
        }}
        localization={{
          toolbar: { searchPlaceholder: "Pesquisar" },
          header: { actions: "Ações" },
          body: {
            editRow: { deleteText: "Excluir este usuário ?" },
            emptyDataSourceMessage: "Nenhum resultado",
          },
          pagination: {
            labelRowsSelect: "Resultados",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        actions={[
          ...(isAdm
            ? [
                {
                  icon: () => <Edit />,
                  tooltip: "Editar",
                  onClick: (_, route: BusRoute) => handleEditRoute(route),
                },
              ]
            : []),
          ...(isAdm || isOperatorManager
            ? [
                {
                  icon: () => <AccessTime />,
                  tooltip: "Horários",
                  onClick: (_, route: BusRoute) => handleSchedule(route),
                },
              ]
            : []),
          ...(!isOperatorManager
            ? [
                {
                  icon: () => <DirectionsBus />,
                  tooltip: "Ônibus",
                  onClick: (_, route: BusRoute) => handleSelectBus(route),
                },
                {
                  icon: () => <Cancel />,
                  tooltip: "Remover Ônibus",
                  onClick: (_, route: BusRoute) => handleOpenRemoveBus(route),
                },
              ]
            : []),
        ]}
      />
      <ScheduleModal
        open={modalOpen}
        route={selectedRoute}
        onClose={() => setModalOpen(false)}
      />
      <ChangeBusModal
        open={busModalOpen}
        route={selectedRoute}
        onClose={() => setBusModalOpen(false)}
      />
      <ConfirmRemoBusModal
        open={removeBusModalOpen}
        route={selectedRoute}
        onClose={() => setRemoveBusModalOpen(false)}
      />
      <EditRouteModal
        open={editRouteModalOpen}
        route={selectedRoute}
        updateRoute={setSelectedRoute}
        onClose={() => setEditRouteModalOpen(false)}
      />
    </>
  );
}
