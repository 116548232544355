import React, { memo, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClustererF,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import "./map.css";

// config
import { REACT_APP_MAP_KEY } from "config/envs";
// hooks
import { useStores } from "stores";
import { GPSApi } from "api";
import { cities } from "constants/cities";

export const center = {
  lat: -7.192828,
  lng: -48.204862,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

type Props = {
  route: BusRoute;
  showRouteBuses: boolean;
  showAllStop: boolean;
  showRouteStop: boolean;
  busStops: BusStop[];
  isAddBusStop: boolean;
  trackingBus: boolean;
  busStopLatLang: React.MutableRefObject<LatLng | undefined>;
  onSelectBusStop(busStop: BusStop): void;
  setIsAddBusStop(isAdd: boolean): void;
};

export const MapComponent = memo(
  ({
    route = {} as BusRoute,
    busStops = [],
    isAddBusStop,
    busStopLatLang,
    showRouteBuses,
    showRouteStop,
    showAllStop,
    trackingBus,
    onSelectBusStop,
    setIsAddBusStop,
  }: Props) => {
    const { auth } = useStores();
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: REACT_APP_MAP_KEY,
      language: "pt-BR",
    });

    const [markerDraggableIndex, setMarkerDraggableIndex] = useState(-1);
    const [buses, setBuses] = useState<Bus[]>([]);

    // refs
    const closeSession = useRef(() => {});
    const isTracking = useRef(false);
    const isFirstResponse = useRef(true);

    const city = auth?.citySelected?.id;
    const token = cities[city].token;
    const accessType = auth.userData?.access_type;
    const isAdm = accessType === "admin";

    const startTrack = async () => {
      if (isTracking.current) return;

      await GPSApi.openSession(token);
      const devices = await GPSApi.getDevices(city);

      const wsConnection = new WebSocket(
        "wss://rastreamento.moveciti.com/api/socket",
      );
      closeSession.current = () => wsConnection.close();

      wsConnection.onopen = () => {
        if (isTracking.current) return;
        // Connection opened
        console.log("WebSocket connection opened");
        isTracking.current = true;
      };
      wsConnection.onmessage = (e) => {
        const positions: GpsPosition[] = JSON.parse(e.data).positions || [];

        if (isFirstResponse.current) {
          isFirstResponse.current = false;
          return setBuses(
            positions.map((position) => {
              const device = devices.find(
                (device) => device.id === position.deviceId,
              );

              return {
                id: device?.id,
                name: device?.name || "",
                disabled: device?.disabled,
                positionId: device?.positionId || 0,
                groupId: device?.groupId || 0,
                status: device?.status || "",
                latitude: position.latitude,
                longitude: position.longitude,
                course: position.course,
                attributes: {
                  route_id: device?.attributes.route_id || "",
                  route_name: device?.attributes.route_name || "",
                  ignition: position.attributes.ignition,
                },
              };
            }),
          );
        }

        setBuses((current) => {
          let busesRes: Bus[] = current;

          if (positions.length > 0) {
            busesRes = busesRes.map((bus) => {
              const position = positions.find(
                (position) => position.deviceId === bus.id,
              );

              if (position || current.length === 0) {
                return {
                  ...bus,
                  latitude: position.latitude,
                  longitude: position.longitude,
                  course: position.course,
                  attributes: {
                    ...bus.attributes,
                    ignition: position.attributes.ignition,
                  },
                };
              }
              return bus;
            });
          }

          return busesRes;
        });
      };

      wsConnection.onerror = (e) => {
        console.log("Error", e);
        isTracking.current = false;
        wsConnection.close();
      };
      wsConnection.onclose = (e) => {
        // Connection closed
        console.log("Closed", e.code, e.reason);
        isTracking.current = false;
        isFirstResponse.current = true;
      };
    };

    const stopTrack = () => {
      isTracking.current = false;
      closeSession.current();
      setBuses([]);
    };

    useEffect(() => {
      if (!isTracking.current && trackingBus) {
        startTrack();
      } else if (isTracking.current && !trackingBus) {
        stopTrack();
      }

      return () => {
        closeSession.current();
      };
    }, [trackingBus]);

    // const handleSetdraggable = (index: number) => {
    //   if (markerDraggableIndex === index) {
    //     setMarkerDraggableIndex(-1);
    //   } else {
    //     setMarkerDraggableIndex(index);
    //   }
    //   busStopLatLang.current = undefined;
    // };

    const handleAddBusStop = (e) => {
      if (!isAddBusStop) return;
      const latitude = e.latLng.lat();
      const longitude = e.latLng.lng();

      const newBusStop = {
        latitude,
        longitude,
        description: "Novo ponto",
      } as BusStop;

      onSelectBusStop(newBusStop);
    };

    const handleClicked = (busStop: BusStop) => {
      setMarkerDraggableIndex(-1);
      const stop = busStop;

      if (busStopLatLang.current) {
        stop.latitude = busStopLatLang.current.latitude;
        stop.longitude = busStopLatLang.current.longitude;
      }

      onSelectBusStop(stop);
    };

    if (!isLoaded) return <div>Carregando...</div>;

    const renderAllBusStops = () =>
      busStops.map((marker, index) => (
        <Marker
          draggable={markerDraggableIndex === index}
          position={{ lat: marker.latitude, lng: marker.longitude }}
          key={`${index}-${marker.latitude}`}
          onClick={() => handleClicked(marker)}
          // onRightClick={() => handleSetdraggable(index)}
          // onDragEnd={(e) => {
          //   const latitude = e.latLng.lat();
          //   const longitude = e.latLng.lng();
          //   busStopLatLang.current = { latitude, longitude };
          // }}
        />
      ));

    const renderRouteStop = () =>
      busStops
        .filter((busStop) =>
          busStop.linked_routes?.find((linked) => linked.route_id === route.id),
        )
        .map((marker, index) => (
          <Marker
            draggable={markerDraggableIndex === index}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            key={`${index}-${marker.latitude}-${route.id}`}
            onClick={() => handleClicked(marker)}
            label={{
              text: marker.linked_routes
                ?.find((linked) => linked.route_id === route.id)
                ?.order.toString(),
              className: "label",
            }}
          />
        ));

    const renderBusMarkers = (clusterer: any): any =>
      buses
        .filter((bus) =>
          showRouteBuses ? route?.buses_ids?.includes(bus.id) : true,
        )
        .map((marker, index) => (
          <Marker
            position={{ lat: marker.latitude, lng: marker.longitude }}
            key={`${index}-${marker.latitude}`}
            clusterer={clusterer}
            options={{
              icon: {
                url: require("assets/BusWhitePin.png"),
                scaledSize: new window.google.maps.Size(35, 35),
                anchor: new window.google.maps.Point(17.5, 17.5),
              },
              label: {
                text: marker.name,
                className: "label",
              },
            }}
          />
        ));

    return (
      <div style={{ height: "100%" }}>
        <GoogleMap
          center={auth.citySelected}
          mapContainerStyle={containerStyle}
          zoom={12.5}
          onClick={handleAddBusStop}
        >
          <Polyline
            path={route.route?.map((item) => ({
              lat: item.latitude,
              lng: item.longitude,
            }))}
            options={{
              strokeColor: "#266FEF",
              strokeOpacity: 1,
              strokeWeight: 3,
            }}
          />
          {showAllStop && renderAllBusStops()}
          {showRouteStop && renderRouteStop()}
          <MarkerClustererF maxZoom={15} minimumClusterSize={5}>
            {(clusterer) => renderBusMarkers(clusterer)}
          </MarkerClustererF>

          {isAdm && (
            <div style={{ position: "absolute", right: 10, bottom: 200 }}>
              <Button
                variant="contained"
                color={isAddBusStop ? "primary" : "inherit"}
                onClick={() => setIsAddBusStop(!isAddBusStop)}
              >
                <Add />
              </Button>
            </div>
          )}
        </GoogleMap>
      </div>
    );
  },
);
