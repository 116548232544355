import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  makeStyles,
  Checkbox,
  TextField,
  InputLabel,
  ListItemText,
  Input,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  isAddBusStop: boolean;
  showRouteStop: boolean;
  busStop: BusStop;
  routeSelectedId: string;
  onClose(): void;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 500,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    maxWidth: 400,
    minWidth: 300,
  },
}));

export function BusStopInfoModal({
  open,
  busStop,
  isAddBusStop,
  routeSelectedId,
  showRouteStop,
  onClose,
}: Props) {
  const { busRoutes, auth } = useStores();
  const classes = useStyles();

  const [routesIds, setRouteIds] = useState([]);
  const [busStopDescription, setBusStopDescription] = useState("");
  const [busStopOrder, setBusStopOrder] = useState("");

  const accessType = auth.userData?.access_type;
  const isOperatorManager = accessType === "operator_manager";
  const orderArray = Array.from({ length: 150 }, (_, i) => i + 1);

  useEffect(() => {
    if (!open) return;
    if (busStop.description) {
      setBusStopDescription(busStop.description);
    }
    if (busStop.linked_routes) {
      setRouteIds(busStop.linked_routes.map((route) => route.route_id));
    }
    const linkedRoute = busStop?.linked_routes?.find(
      (route) => route.route_id === routeSelectedId,
    );

    if (linkedRoute) {
      setBusStopOrder(String(linkedRoute.order));
    }
  }, [busStop, open]);

  const handleClose = () => {
    setRouteIds([]);
    setBusStopDescription("");
    setBusStopOrder("");
    onClose();
  };

  const handleDelete = async () => {
    if (window.confirm("Deseja realmente deletar esta parada?")) {
      await busRoutes.deleteBusStop(busStop.index);
      handleClose();
    }
  };

  const handleUpdate = async () => {
    if (isAddBusStop) {
      await busRoutes.addBusStop(busStop, busStopDescription, routesIds);
      handleClose();
      return;
    }
    busRoutes.updateBusStop(busStop, busStopDescription, routesIds);
    handleClose();
  };

  const handleUpdateOrder = async () => {
    if (!busStopOrder) {
      return alert("Selecione uma ordem para a parada");
    }
    busRoutes.updateBusStopOrder(busStop, routeSelectedId, busStopOrder);
    handleClose();
  };

  const handleChange = (event) => {
    setRouteIds(event.target.value);
  };

  const renderRouteName = (routesId: string[]) =>
    busRoutes.routesData
      .filter((route) => routesId.includes(route.id))
      .map((route) => route.name)
      .join(", ");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isAddBusStop ? "Adicionar" : "Editar"} Parada
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Descrição"
          value={busStopDescription}
          onChange={({ target }) => setBusStopDescription(target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
          disabled={isOperatorManager || showRouteStop}
        />
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">Rotas</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            fullWidth
            value={routesIds}
            onChange={handleChange}
            input={<Input />}
            renderValue={renderRouteName}
            MenuProps={MenuProps}
            disabled={isOperatorManager || showRouteStop}
          >
            {busRoutes.routesData.map((route) => (
              <MenuItem
                key={route.id}
                value={route.id}
                style={{
                  backgroundColor:
                    route.id === routeSelectedId ? "#ffcaa2" : "",
                }}
              >
                <Checkbox checked={routesIds.indexOf(route.id) > -1} />
                <ListItemText primary={route.name + "- " + route.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showRouteStop && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="demo-mutiple-checkbox-label">Ordem</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              fullWidth
              value={busStopOrder}
              onChange={({ target }) => setBusStopOrder(target.value as string)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {orderArray.map((order) => (
                <MenuItem key={order} value={order}>
                  <ListItemText primary={order} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="inherit"
          disabled={busRoutes.isRequestLoading}
        >
          Fechar
        </Button>
        {!isOperatorManager && !showRouteStop && (
          <Button
            onClick={handleDelete}
            color="secondary"
            disabled={busRoutes.isRequestLoading}
          >
            Deletar Parada
          </Button>
        )}
        {!isOperatorManager && !showRouteStop && (
          <Button
            disabled={busRoutes.isRequestLoading}
            onClick={handleUpdate}
            color="primary"
          >
            {busRoutes.isRequestLoading ? (
              <CircularProgress size={18} />
            ) : (
              "Atualizar"
            )}
          </Button>
        )}
        {showRouteStop && (
          <Button
            disabled={busRoutes.isRequestLoading}
            onClick={handleUpdateOrder}
            color="primary"
          >
            Atualizar Ordem
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
