import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Hidden,
  Switch,
  Typography,
} from "@material-ui/core";
import { MapComponent } from "components";

import { useEffect, useRef, useState } from "react";

import { useStores } from "stores";
import { BusStopInfoModal } from "components/BusStopInfoModal";

export function RouteMap() {
  //hooks
  const { busRoutes, auth } = useStores();

  const [routeSelected, setRouteSelected] = useState<BusRoute>();
  const [busStopSelected, setBusStopSelected] = useState({} as BusStop);
  const [showBusStopModal, setShowBusStopModal] = useState(false);
  const [isAddBusStop, setIsAddBusStop] = useState(false);
  const [showAllStop, setShowAllStop] = useState(false);
  const [showRouteStop, setShowRouteStop] = useState(false);
  const [showRouteBuses, setShowRouteBuses] = useState(false);
  const [trackingBus, setTrackingBus] = useState(false);

  const busStopLatLang = useRef<LatLng>();

  const accessType = auth.userData?.access_type;
  const isOperatorManager = accessType === "operator_manager";

  useEffect(() => {
    if (busRoutes.routesData?.length === 0) {
      busRoutes.loadRoutes();
    }

    if (busRoutes.busStopsData?.length === 0) {
      busRoutes.loadBusStop();
    }
  }, [busRoutes.routesData, busRoutes.busStopsData]);

  const routes = busRoutes.routesData.sort(
    (a, b) => Number(a.route_number) - Number(b.route_number),
  );

  const truncateText = (text: string, maxLength: number) => {
    if (!text) return "";
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const onSelectBusStop = (busStop: BusStop) => {
    setBusStopSelected(busStop);
    setShowBusStopModal(true);
  };

  const handleClosedModal = () => {
    setShowBusStopModal(false);
    setIsAddBusStop(false);
    busStopLatLang.current = undefined;
  };

  const handleShowBusStops = (type: "all" | "route") => {
    if (type === "all") {
      setShowAllStop(!showAllStop);
      setShowRouteStop(false);
    } else {
      setShowAllStop(false);
      setShowRouteStop(!showRouteStop);
    }
  };

  return (
    <Container style={{ height: "100%", padding: 0 }}>
      <Grid container style={{ height: "100%" }}>
        {/* @ts-ignore */}
        <Hidden smDown>
          <Grid
            item
            xs={3}
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h6">Rotas - {routes.length}</Typography>
            {!isOperatorManager && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={trackingBus}
                      onChange={() => setTrackingBus(!trackingBus)}
                      name="checkedTest"
                      color="primary"
                    />
                  }
                  label={trackingBus ? "Rastrear" : "Não Rastrear"}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showRouteBuses}
                      onChange={() => setShowRouteBuses(!showRouteBuses)}
                      name="checkedTest"
                      color="primary"
                    />
                  }
                  label={showRouteBuses ? "Onibus Rota" : "Todos os Onibus"}
                />
              </>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={showAllStop}
                  onChange={() => handleShowBusStops("all")}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Mostrar todos os pontos"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showRouteStop}
                  onChange={() => handleShowBusStops("route")}
                  name="checkedRoute"
                  color="primary"
                />
              }
              label="Mostrar os pontos da rota"
            />
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
              }}
            >
              {routes.map((route) => (
                <Button
                  key={route.id}
                  variant={
                    routeSelected?.id === route.id ? "contained" : "outlined"
                  }
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 5,
                  }}
                  onClick={() => setRouteSelected(route)}
                >
                  <Typography variant="h6" style={{ fontSize: 14 }}>
                    {route.route_number} - {route.name} <br />
                    <Typography noWrap variant="body2" style={{ fontSize: 12 }}>
                      {truncateText(route.description, 20)}
                    </Typography>
                  </Typography>
                </Button>
              ))}
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={9}>
          <MapComponent
            route={routeSelected}
            busStops={busRoutes.busStopsData}
            isAddBusStop={isAddBusStop}
            busStopLatLang={busStopLatLang}
            showRouteBuses={showRouteBuses}
            showRouteStop={showRouteStop}
            showAllStop={showAllStop}
            trackingBus={trackingBus}
            onSelectBusStop={onSelectBusStop}
            setIsAddBusStop={setIsAddBusStop}
          />
        </Grid>
      </Grid>
      <BusStopInfoModal
        open={showBusStopModal}
        isAddBusStop={isAddBusStop}
        busStop={busStopSelected}
        showRouteStop={showRouteStop}
        routeSelectedId={routeSelected?.id}
        onClose={handleClosedModal}
      />
    </Container>
  );
}
