import { create } from "zustand";
import { toast } from "react-toastify";

import { busRoutesRef, citiesRef, functions, storage } from "config/firebase";
import { useAuthStore } from "./auth.store";

type BusRoutesStore = {
  routesData: BusRoute[];
  busesData: Bus[];
  busStopsData: BusStop[];
  isLoading: boolean;
  isRequestLoading: boolean;

  updateRoute: (data: BusRoute) => Promise<void>;
  deleteBusStop: (busStopIndex: number) => Promise<void>;
  updateBusStop: (
    busStop: BusStop,
    description: string,
    routes_ids: string[],
  ) => Promise<void>;
  updateBusStopOrder: (
    busStop: BusStop,
    routeId: string,
    order: string,
  ) => Promise<void>;
  addBusStop: (
    busStop: BusStop,
    description: string,
    routes_ids: string[],
  ) => Promise<void>;
  loadRoutes: () => Promise<void>;
  loadBuses: () => Promise<void>;
  loadBusStop: () => Promise<void>;
  convertBusStopToGeoJSON: (start: number, end: number) => Promise<void>;
  updateBusRoute: (
    busId: number | string,
    routeId: string,
    routeName: string,
    keepPreviousBuses?: boolean,
  ) => Promise<void>;
  updateRouteInfo: (
    routeId: string,
    routeNumber: string,
    routeName: string,
    routeDescription: string,
  ) => Promise<void>;
  updateBusMaintenance: (
    busId: number,
    inMaintenance: boolean,
  ) => Promise<void>;
  updateRouteSchedule: (
    routeId: string,
    weeklySchedule: ScheduleTime[],
    saturdaySchedule: ScheduleTime[],
    sundaySchedule: ScheduleTime[],
  ) => Promise<void>;
  removeBusesFromRoute: (routeId: string) => Promise<void>;
};

export const useBusRoutesStore = create<BusRoutesStore>()((set, get) => ({
  routesData: [],
  busesData: [],
  busStopsData: [],
  isLoading: false,
  isRequestLoading: false,

  updateRoute: async (data) => {
    try {
      const city = useAuthStore.getState().citySelected;
      await busRoutesRef(city.id).doc(data.id).update(data);
      await get().loadRoutes();
    } catch (error) {
      console.log(error);
    }
  },

  updateRouteInfo: async (
    routeId,
    routeNumber,
    routeName,
    routeDescription,
  ) => {
    try {
      set({ isRequestLoading: true });
      const city = useAuthStore.getState().citySelected;
      await busRoutesRef(city.id)
        .doc(routeId)
        .update({
          route_number: routeNumber,
          name: routeName,
          description: routeDescription || "",
        });
      await get().loadRoutes();
      toast.success("Rota atualizada com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar rota");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  loadRoutes: async () => {
    set({ isLoading: true });

    try {
      const city = useAuthStore.getState().citySelected;
      const routesDoc = await busRoutesRef(city.id).get();
      const routesData = routesDoc.docs
        .sort((a, b) => a.data().route_number - b.data().route_number)
        .map((doc) => {
          const route = doc.data() as BusRoute;
          route.id = doc.id;
          route.bus_stops = route.bus_stops || [];
          return route;
        });

      set({ routesData, isLoading: false });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  loadBusStop: async () => {
    set({ isLoading: true });

    try {
      const city = useAuthStore.getState().citySelected;
      const cityDoc = await citiesRef.doc(city.id).get();
      const { bus_stops } = cityDoc.data() as CityInfo;
      const busStopsData = bus_stops.map((busStop, index) => ({
        ...busStop,
        index,
      }));

      set({ isLoading: false, busStopsData });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  loadBuses: async () => {
    set({ isLoading: true });

    try {
      const cityGroupId = useAuthStore.getState().citySelected.groupId;
      const { data } = await functions.httpsCallable("getBuses")();
      const busesData = data
        .filter((bus: Bus) => !bus.disabled && bus.groupId === cityGroupId)
        .sort((a: Bus, b: Bus) => a.name.localeCompare(b.name));

      set({ isLoading: false, busesData });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  convertBusStopToGeoJSON: async (start_index, end_index) => {
    set({ isLoading: true });

    try {
      const { items } = await storage.ref("/locations").listAll();
      const filesUls = await Promise.all(
        items.sort().map(async (item) => {
          const url = await item.getDownloadURL();
          return { name: item.name, url };
        }),
      );
      await functions.httpsCallable("kmzRouteToJson")({
        files: filesUls,
        start_index,
        end_index,
      });

      set({ isLoading: false });
    } catch (error) {
      console.log({ error });
      set({ isLoading: false });
    }
  },

  updateBusRoute: async (busId, routeId, routeName, keepPreviousBuses) => {
    try {
      set({ isRequestLoading: true });
      const authStore = useAuthStore.getState();
      const city = useAuthStore.getState().citySelected;

      await functions.httpsCallable("setBusRoute")({
        bus_id: busId,
        route_id: routeId,
        route_name: routeName,
        keep_previous_buses: keepPreviousBuses,
        operator_name: authStore.userData.name,
        operator_email: authStore.userData.email,
        city: city.id,
      });
      await get().loadRoutes();
      toast.success("Rota atualizada com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar rota");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  removeBusesFromRoute: async (routeId) => {
    try {
      const city = useAuthStore.getState().citySelected;
      const authStore = useAuthStore.getState();
      set({ isRequestLoading: true });

      await functions.httpsCallable("removeAllBusFromRoute")({
        route_id: routeId,
        city: city.id,
        operator_name: authStore.userData.name,
        operator_email: authStore.userData.email,
      });
      await get().loadRoutes();
      toast.success("Ônibus removidos com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao remover ônibus");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateBusMaintenance: async (busId, inMaintenance) => {
    try {
      set({ isRequestLoading: true });
      await functions.httpsCallable("setBusMaintenance")({
        bus_id: busId,
        in_maintenance: inMaintenance,
      });
      await get().loadBuses();
      toast.success("Informação atualizada com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar manutenção");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateRouteSchedule: async (
    routeId,
    weeklySchedule,
    saturdaySchedule,
    sundaySchedule,
  ) => {
    try {
      set({ isRequestLoading: true });
      const city = useAuthStore.getState().citySelected;
      await busRoutesRef(city.id).doc(routeId).update({
        weekly_bus_schedule: weeklySchedule,
        saturday_bus_schedule: saturdaySchedule,
        sunday_bus_schedule: sundaySchedule,
      });
      get().loadRoutes();
      toast.success("Horários atualizados com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar horários");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  deleteBusStop: async (busStopIndex) => {
    try {
      set({ isRequestLoading: true });
      const city = useAuthStore.getState().citySelected;
      const busStops = get().busStopsData;
      busStops.splice(busStopIndex, 1);
      await citiesRef.doc(city.id).update({ bus_stops: busStops });
      toast.success("Parada deletada com sucesso");
      set({ isRequestLoading: false, busStopsData: busStops });
    } catch (error) {
      toast.error("Erro ao deletar parada");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateBusStop: async (busStop, description, routes_ids) => {
    try {
      set({ isRequestLoading: true });
      const city = useAuthStore.getState().citySelected;
      const busStops = get().busStopsData;

      const linkedRoutes = busStop.linked_routes || [];
      const cleanedList = routes_ids.map((route_id) => ({
        route_id,
        order:
          linkedRoutes.find((route) => route.route_id === route_id)?.order || 0,
      }));

      busStops[busStop.index] = {
        ...busStop,
        description,
        linked_routes: cleanedList,
      };

      toast.success("Parada atualizada com sucesso");
      set({ isRequestLoading: false, busStopsData: busStops });
      await citiesRef.doc(city.id).update({ bus_stops: busStops });
    } catch (error) {
      toast.error("Erro ao atualizar parada");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateBusStopOrder: async (busStop, routeId, order) => {
    try {
      const city = useAuthStore.getState().citySelected;
      const busStops = get().busStopsData;
      const linkedRoutes = busStop.linked_routes || [];
      const cleanedList = linkedRoutes.filter(
        (route) => route.route_id !== routeId,
      );

      busStops[busStop.index] = {
        ...busStop,
        linked_routes: [
          ...cleanedList,
          {
            route_id: routeId,
            order: Number(order),
          },
        ],
      };

      set({ isRequestLoading: false, busStopsData: busStops });
      await citiesRef.doc(city.id).update({ bus_stops: busStops });
      toast.success("Ordem atualizada com sucesso");
    } catch (error) {
      toast.error("Erro ao atualizar ordem");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  addBusStop: async (busStop, description, routes_ids) => {
    try {
      set({ isRequestLoading: true });
      const busStops = get().busStopsData;
      const city = useAuthStore.getState().citySelected;
      const newLinkedRoutes = [
        ...(busStop.linked_routes || []),
        ...routes_ids.map((route_id) => ({
          route_id,
          order: 0,
        })),
      ];

      busStops.push({
        ...busStop,
        description,
        linked_routes: newLinkedRoutes,
      });
      await citiesRef.doc(city.id).update({ bus_stops: busStops });
      toast.success("Parada adicionada com sucesso");
      set({ isRequestLoading: false, busStopsData: busStops });
    } catch (error) {
      toast.error("Erro ao adicionar parada");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },
}));
